<template>
  <div id="shop">
    <section>
      <van-pull-refresh @refresh="refresh" v-model="refreshing">
        <div class="header-wrap">
          <div
            class="tab-item"
            :class="{ active: tab === 'live' }"
            @click="toggleTab('live')"
          >
            <van-badge :content="market.length" color="#1989fa">
              直播中
            </van-badge>
          </div>
          <div
            class="tab-item"
            :class="{ active: tab === 'hp' }"
            @click="toggleTab('hp')"
          >
            <van-badge :content="productsCnt" color="#1989fa"> 货盘 </van-badge>
          </div>
          <van-search
            v-model="searchText"
            placeholder="请输入搜索关键词"
            @search="search"
            @clear="clearSearch"
          />
        </div>
        <van-list
          v-model="isLoading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          :error-text="errorText"
          @load="fetch"
        >
          <div
            class="product-item"
            v-for="(item, index) in showProducts"
            :key="item.id"
            :class="{ hp: tab === 'hp' && item.isInMarket }"
          >
            <div class="shopInfoView">
              <div class="rankText">{{ item.rankIndex }}</div>
              <div class="shopImg">
                <img :src="item.cover" :alt="item.title" />
              </div>
              <div class="shopInfo">
                <strong>{{ item.title }}</strong>
                <div class="price">
                  <strong>¥{{ (item.price / 100).toFixed(2) }}</strong>
                </div>
                <div class="shopOpe">
                  <div class="ope">
                    <van-button
                      type="default"
                      block
                      size="small"
                      @click="top(index)"
                      v-if="
                        index &&
                        ((liveStatus.value && item.isInMarket) ||
                          !liveStatus.value)
                      "
                      >置顶</van-button
                    >
                    <van-button
                      type="default"
                      block
                      size="small"
                      @click="showPosition(index)"
                      v-if="
                        (liveStatus.value && item.isInMarket) ||
                        !liveStatus.value
                      "
                      >位置</van-button
                    >
                    <van-button
                      type="danger"
                      block
                      size="small"
                      v-if="item.isInMarket && liveStatus.value"
                      @click="popProduct(index)"
                      >下架</van-button
                    >
                    <van-button
                      type="primary"
                      block
                      size="small"
                      v-else-if="liveStatus.value"
                      @click="pushProduct(index)"
                      >上架</van-button
                    >
                    <van-button
                      type="info"
                      block
                      size="small"
                      @click="clickShowDetail(item)"
                      >关键词</van-button
                    >
                    <van-button
                      type="primary"
                      block
                      size="small"
                      v-if="tab === 'live' && popProductId !== item.id"
                      @click="setCurrent(item)"
                      >讲解商品</van-button
                    >
                    <van-button
                      type="danger"
                      block
                      size="small"
                      v-if="tab === 'live' && popProductId === item.id"
                      @click="cancelCurrent(item)"
                      >取消讲解</van-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <p class="saleSituation">
              <van-tag
                type="primary"
                v-for="(kw, index) in item.keywords"
                :key="index"
                >{{ kw }}</van-tag
              >
            </p>
          </div>
          <van-empty
            v-if="tab === 'live' && liveStatus.value === false"
            description="还没开播，没有货盘数据"
          />
          <van-empty
            v-else-if="
              tab === 'live' &&
              liveStatus.value === true &&
              !showProducts.length
            "
            description="暂无货盘数据"
          />
        </van-list>
      </van-pull-refresh>
    </section>

    <!-- 详情 -->
    <van-dialog
      v-model="showDetail"
      title="商品关键词"
      confirmButtonText="关闭"
    >
      <div class="shopDetailView" v-if="showDetail">
        <div class="shopInfoView">
          <div class="shopImg">
            <img :src="detailObj.cover" :alt="detailObj.title" />
          </div>
          <div class="shopInfo">
            <strong>{{ detailObj.title }}</strong>
            <span>￥{{ (detailObj.price / 100).toFixed(2) }}</span>
          </div>
        </div>
        <van-divider content-position="left" id="copy" :data-words="kw"
          >关键词</van-divider
        >
        <div class="tag-wrap">
          <van-tag
            type="primary"
            v-for="(kw, index) in detailObj.keywords"
            :key="index"
            closeable
            @close="removeTag(index)"
            >{{ kw }}</van-tag
          >
        </div>
        <div class="addBtn-wrap">
          <van-field v-model.trim="keyword" center placeholder="请输入关键词">
            <template #button>
              <van-button size="small" type="primary" @click="addKeyword"
                >添加</van-button
              >
            </template>
          </van-field>
        </div>
      </div>
    </van-dialog>
    <!--位置弹窗-->
    <van-dialog
      v-model="position"
      title="指定位置"
      show-cancel-button
      @confirm="confirmPosition"
    >
      <van-cell
        title="原位置"
        :value="
          showProducts[positionOld] ? showProducts[positionOld].rankIndex : 0
        "
      />
      <van-field
        v-model="positionVal"
        label="位置"
        placeholder="请输入位置,1-100"
        type="digit"
        :max="100"
        :min="1"
        style="margin-top: 20px; margin-bottom: 20px"
      />
    </van-dialog>
    <van-overlay :show="showLoading" z-index="12">
      <div class="loading-wrap">
        <van-loading size="24px" vertical>{{ loadingText }}</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import ClipboardJS from "clipboard";
import { mapActions, mapState } from "vuex";
export default {
  name: "Shop",

  data() {
    return {
      showDetail: false,
      keyword: "", //添加商品的关键词
      isLoading: false, //加载
      finished: false, //请求数据完成
      refreshing: false, //刷新数据完成
      error: false, //接口错误
      errorText: "", //错误文本
      detailObj: {},
      products: [],
      page: 1,
      position: false, //位置弹窗
      positionOld: -1, //原位置
      positionVal: 1,
      searchText: null,
      tab: "hp",
      liveStatus: {
        value: false,
        duration: 0,
      },
      market: [], //小黄车数据
      productsCnt: 0, //货盘总数量
      hpRank: [], //货盘的rank数组
      showLoading: false, //状态加载中
      loadingText: "",
      productHistory: {}, //商品的历史信息 以product_id 来存储，用于hp 等信息
      statusTime: null, //定时获取直播状态
      popProductId: null, //主动讲解的商品
      canGetPromition: true, //截流获取promtion信息请求
      hasClick: false, // 记录一下，是否手动切换过，
    };
  },
  created() {
    this.fetchRank();
  },
  watch: {
    // 修改pid后重置数据
    async pid() {
      this.liveStatus = {
        value: false,
        duration: 0,
      };
      this.refresh();
      this.fetchRank();
    },
    message: {
      deep: true,
      handler() {
        if (this.message) {
          switch (this.message.event) {
            case "liveState":
              clearTimeout(this.statusTime);
              this.liveStatus = this.message.params;
              this.statusTime = setTimeout(() => {
                this.loading = false;
                this.liveStatus = {
                  value: false,
                  duration: 0,
                };
              }, 15e3);
              if (!this.hasClick && this.liveStatus.value) {
                this.tab = "live";
              }
              this.hasClick = true;
              break;
            case "productsList":
              //没有开播就丢弃数据
              if (!this.liveStatus.value) {
                this.market = [];
                return;
              }
              this.popProductId = this.message.params.popupProduct
                ? this.message.params.popupProduct.id
                : null;
              this.market = this.message.params.value;
              this.hpRank = this.market
                .filter((item) => this.productHistory[item])
                .map((item) => this.productHistory[item].id);
              this.getProductByProductId();
              break;
            default:
              break;
          }
        }
      },
    },
    liveStatus: {
      deep: true,
      handler(newVal, oldVal) {
        //下播以后，用表中排序
        if (newVal.value !== oldVal.value && newVal.value === false) {
          this.fetchRank();
        }
      },
    },
  },
  computed: {
    ...mapState(["pid", "accountInfo", "message"]),
    kw() {
      if (this.detailObj && this.detailObj.keywords) {
        return this.detailObj.keywords.join(",");
      } else {
        return "";
      }
    },
    showProducts() {
      let temp = [];
      let hpObj = {};
      let rankObj = {};
      this.hpRank.forEach((item, index) => (rankObj[item] = index + 1));
      this.market.forEach((item) => (hpObj[item] = true));
      let defaultIndex = this.hpRank.length + 1; //默认的rank是前100，不在榜单的就从101开始
      this.products.forEach((item) => {
        if (hpObj[item.product_id]) {
          item.isInMarket = true;
        } else {
          item.isInMarket = false;
        }
        if (rankObj[item.id]) {
          item.rankIndex = rankObj[item.id];
        } else {
          item.rankIndex = defaultIndex++;
        }
      });
      if (this.tab === "live") {
        temp = this.products.filter((item) => hpObj[item.product_id]);
      } else {
        temp = this.products.filter((item) => !hpObj[item.product_id]);
      }
      temp.sort((a, b) => a.rankIndex - b.rankIndex);
      return temp;
    },
  },
  methods: {
    ...mapActions(["sendCommandToApp"]),
    search() {
      console.log(">>> search", this.searchText);
      this.refresh();
    },
    clearSearch() {
      this.searchText = "";
      this.refresh();
    },
    async refresh() {
      this.page = 1;
      this.refreshing = true;
      this.finished = false;
      await this.fetch();
      this.refreshing = false;
    },
    async fetchRank() {
      let ret = await this.$http.get(
        `devices/${this.accountInfo.id}/huopan-rank`
      );
      this.hpRank = ret;
    },
    async fetch() {
      //已经请求完所有的数据了
      if (this.finished) return; //
      this.error = false;
      this.errorText = "";
      try {
        let ret = await this.$http.get(
          `/devices/${this.accountInfo.id}/huopan-products`,
          {
            page: this.page++,
            keywords: this.searchText,
          }
        );
        //看商品是否在列表中
        let temp = {};
        this.products.forEach((item) => (temp[item.id] = temp));
        if (this.page !== 2) {
          ret.data = ret.data.filter((item) => {
            if (temp[item.id]) {
              console.log(">>>>该商品重复了，", temp[item.id]);
            }
            return !temp[item.id];
          });
        }
        ret.data.forEach((item) => {
          item.isInMarket = false;
          this.productHistory[item.product_id] = item;
        });
        this.productsCnt = ret.cnt;
        if (this.page === 2) {
          this.products = ret.data;
        } else {
          this.products = this.products.concat(ret.data);
        }
        if (ret && ret.data && ret.data.length < 200) {
          this.finished = true;
        }
      } catch (e) {
        this.error = true;
        if (e && e.response && e.response.status !== 200) {
          if (e.response.status === 404) {
            this.errorText = `你没有货盘数据,请联系客服`;
          } else {
            this.errorText = `发生异常,接口:${e.response.status},请联系客服`;
          }
        }
      }
      this.isLoading = false;
    },
    // 点击关键词
    clickShowDetail(item) {
      this.detailObj = item;
      this.showDetail = true;
      this.keyword = "";
      new ClipboardJS("#copy", {
        text: function (trigger) {
          return trigger.getAttribute("data-words");
        },
      });
    },
    async removeTag(index) {
      this.detailObj.keywords.splice(index, 1);
      this.updateKeyword();
    },
    async addKeyword() {
      let temp = this.keyword.split(/[,，]/);
      temp = temp.filter((item) => !this.detailObj.keywords.includes(item));
      if (!temp.length) {
        this.keyword = "";
        return;
      }
      this.detailObj.keywords = this.detailObj.keywords.concat(temp);
      this.keyword = "";
      this.updateKeyword();
    },
    async updateKeyword() {
      this.sendCommandToApp({
        command: "productKeywordsUpdate",
        params: {
          id: this.detailObj.id,
          keywords: this.detailObj.keywords || [],
        },
      });
      await this.$http.put(
        `/devices/${this.accountInfo.id}/products/${this.detailObj.id}/keywords`,
        {
          data: this.detailObj.keywords || [],
        }
      );
    },
    async top(index) {
      if (this.showLoading) return;
      this.showLoading = true;
      this.loadingText = "更新排序中";
      let item = this.showProducts[index];
      let rank = this.hpRank.slice(0);
      let idx = this.hpRank.findIndex((rItem) => item.id === rItem);
      if (idx > -1) {
        rank.splice(idx, 1);
      }
      rank.unshift(item.id);
      if (
        this.liveStatus &&
        this.liveStatus.value &&
        this.market.length == this.hpRank.length
      ) {
        let listById = {};
        for (let pId in this.productHistory) {
          let temp = this.productHistory[pId];
          listById[temp.id] = temp;
        }
        let data = rank
          .filter((item) => listById[item])
          .map((item) => listById[item].product_id);
        try {
          let ret = await this.sendCommandToApp({
            command: "sortProduct",
            params: {
              value: data.slice(0, 100),
            },
            needBack: true,
          });
          if (ret && ret.code === 0) {
            this.$toast("更新排序成功");
            this.hpRank = rank;
          } else if (ret && ret.msg) {
            this.$toast(ret.msg);
          } else {
            this.$toast("更新排序失败");
          }
          console.log(">>>> 更新排序操作", ret);
        } catch (e) {
          console.log(">>>> 更新排序操作失败", e);
          this.$toast("更新排序失败");
        }
      } else {
        this.hpRank = rank;
      }
      this.updateRank();
      this.showLoading = false;
      this.loadingText = "";
    },

    showPosition(index) {
      this.positionOld = index;
      this.positionVal = null;
      this.position = true;
    },
    async confirmPosition() {
      if (this.showLoading) return;
      this.showLoading = true;
      this.loadingText = "更新排序中";
      let oldRankIndex = this.showProducts[this.positionOld].rankIndex;
      let item = this.showProducts[this.positionOld];
      let rank = this.hpRank.slice(0);
      if (oldRankIndex == this.positionVal) {
        this.showLoading = false;
        this.loadingText = "";
        this.position = false;
        return;
      }
      //查看是否在rank中，在就调整，不在就算了
      let idx = this.hpRank.findIndex((rItem) => rItem === item.id);
      if (idx > -1) {
        rank.splice(idx, 1);
      }
      if (this.positionVal <= 1) {
        rank.unshift(item.id);
      } else if (this.position > 200) {
        rank.push(item.id);
      } else {
        rank.splice(this.positionVal - 1, 0, item.id);
      }
      this.position = false;
      if (
        this.liveStatus &&
        this.liveStatus.value &&
        this.market.length == this.hpRank.length
      ) {
        let listById = {};
        for (let pId in this.productHistory) {
          let temp = this.productHistory[pId];
          listById[temp.id] = temp;
        }
        let data = rank
          .filter((item) => listById[item])
          .map((item) => listById[item].product_id);
        try {
          let ret = await this.sendCommandToApp({
            command: "sortProduct",
            params: {
              value: data.slice(0, 100),
            },
            needBack: true,
          });
          if (ret && ret.code === 0) {
            this.$toast("更新排序成功");
            this.hpRank = rank;
          } else if (ret && ret.msg) {
            this.$toast(ret.msg);
          } else {
            this.$toast("更新排序失败");
          }
          console.log(">>>> 更新排序操作", ret);
        } catch (e) {
          console.log(">>>> 更新排序操作失败", e);
          this.$toast("更新排序失败");
        }
      } else {
        this.hpRank = rank;
      }
      this.showLoading = false;
      this.loadingText = "";
      return this.updateRank();
    },
    async updateRank() {
      if (!this.hpRank.length) return;
      // 上传新的排序
      await this.$http.put(`/devices/${this.accountInfo.id}/huopan-sort`, {
        data: this.hpRank,
      });
    },
    toggleTab(type) {
      if (this.tab !== type) {
        this.tab = type;
      }
      this.hasClick = true;
    },
    async pushProduct(index) {
      if (this.market.length >= 100) {
        return this.$dialog.alert({
          message: "当前货架已满100个商品，请先下架一个",
        });
      }
      if (this.showLoading) return;
      this.loadingText = "上架中...";
      this.showLoading = true;
      let data = Object.assign({}, this.showProducts[index]);
      delete data.rankIndex;
      delete data.isInMarket;
      try {
        let ret = await this.sendCommandToApp({
          command: "pushProduct",
          params: {
            value: [data],
          },
          needBack: true,
        });
        if (ret && ret.success_count === 1) {
          this.hpRank.unshift(data.id);
          this.market.push(data.product_id);
          this.updateRank();
          this.$toast("上架成功");
        } else if (ret && ret.failure_count > 0) {
          this.$toast("上架失败！");
        }
        console.log(">>>> 上架回调", ret);
      } catch (e) {
        console.log(">>>> 上架失败", e);
        this.$toast("上架失败！");
      }
      this.showLoading = false;
    },
    async popProduct(index) {
      if (this.showLoading) return;
      let data = this.showProducts[index];
      let market = this.market.slice(0, this.market.length);
      let idx = market.findIndex((item) => item === data.product_id);
      if (idx > -1) {
        market.splice(idx, 1);
      } else {
        return;
      }
      if (!market.length) {
        return this.$dialog.alert({ message: "下架失败，小黄车不能空" });
      }
      this.loadingText = "下架中...";
      this.showLoading = true;
      let rank = this.market.slice(0, this.market.length);
      rank.splice(index, 1);
      try {
        let ret = await this.sendCommandToApp({
          command: "unbindProduct",
          params: {
            value: [data.product_id],
          },
          needBack: true,
        });
        if (ret && ret.code === 0) {
          this.market = market;
          this.hpRank = this.market
            .filter((item) => this.productHistory[item])
            .map((item) => this.productHistory[item].id);
          this.updateRank();
          this.$toast("下架成功");
        } else if (ret && ret.message) {
          this.$dialog.alert({ title: "下架失败", message: ret.message });
        }
        console.log(">>>> 下架操作", ret);
      } catch (e) {
        console.log(">>>> 下架操作失败", e);
        this.$toast("下架操作失败");
      }
      this.showLoading = false;
    },
    //讲解商品
    async setCurrent(item) {
      if (this.showLoading) return;
      this.showLoading = true;
      this.loadingText = "设置中";
      try {
        let ret = await this.sendCommandToApp({
          command: "popupProduct",
          params: {
            value: item,
          },
          needBack: true,
        });
        console.log(">>>> 设置讲解", ret);
      } catch (e) {
        console.log(">>>> 设置讲解失败", e);
        this.$toast("设置失败！");
      }
      this.showLoading = false;
    },
    //取消讲解
    async cancelCurrent(item) {
      if (this.showLoading) return;
      this.showLoading = true;
      this.loadingText = "操作中";
      try {
        let ret = await this.sendCommandToApp({
          command: "cancelPopupProduct",
          params: {
            value: item,
          },
        });
        console.log(">>>> 取消讲解回调", ret);
      } catch (e) {
        console.log(">>>> 上架失败", e);
        this.$toast("取消讲解失败");
      }
      this.showLoading = false;
    },
    async getProductByProductId() {
      if (!this.canGetPromition) return;
      let productIds = this.market.filter((item) => !this.productHistory[item]);
      if (!productIds.length) return;
      this.canGetPromition = false;
      try {
        let ret = await this.$http.post(`/find-products`, {
          product_ids: productIds,
        });
        ret.forEach((item) => {
          this.productHistory[item.product_id] = { ...item, isInMarket: false };
          this.products.push(item);
        });
      } catch (e) {
        console.log(">>>get promition", e);
      }
      this.canGetPromition = true;
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss">
</style>