<template>
  <div id="product">
    <Shop />
  </div>
</template> 

<script>
import Shop from "@/components/Shop/index.vue";

export default {
  name: "Product",

  components: {
    Shop,
  },
  data() {
    return {
      products: [],
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped src="./index.scss">
</style>